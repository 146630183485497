import React, { useState } from 'react'
import { TopPaneComponent } from './TopPane/TopPaneComponent'
import { AppState, ClauseList } from './AppHelpers'
import { TopMenuBar } from './TopMenuBar'
import { RDGrid } from './Shared/Wrappers/RDGrid'
import { RDButton } from './RDButton'
import { Box, Dialog, DialogContent } from '@mui/material'
import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'
import { Close } from '@material-ui/icons'
import { RDPaletteLight } from '../services/ThemeService'
import { RDIcon } from './Shared/Wrappers/RDIcon'
import { RDTabs } from './TabComponents/RDTabs'
import { RDTab } from './TabComponents/RDTab'
import { RDSwipeableViews } from './Shared/Wrappers/RDSwippableViews'
import { RDFixedTabPanel } from './TabComponents/RDFixedTabPanel'
import { AssistantTabComponent } from './AssistantTab'

interface AddInBodyProps {
  state: AppState
  setState: React.Dispatch<React.SetStateAction<AppState>>
  clauseList: ClauseList[]
}

const AddInBody = (props: AddInBodyProps) => {
  const { state, clauseList, setState } = props
  const [verticalTabValue, setVerticalTabValue] = useState(0)
  // const handleChangeIndex = (index: number) => {
  //   setVerticalTabValue(index)
  // }

  const systemA11yProps = (name: string, index: any) => {
    return {
      id: `scrollable-force-tabpanel-${name}-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${name}-${index}`,
    }
  }
  const SystemSetupTabs = (state: AppState, clauseList: ClauseList[]) => [
    {
      name: 'risk_analysis',
      label: 'Risk Analysis',
      component: <TopPaneComponent token={state.authToken} filter={state.filter} clauseList={clauseList} />,
    },
    {
      name: 'assistant',
      label: 'Assistant',
      component: <AssistantTabComponent token={state.authToken} cacheName={state.contextCacheName} />,
    },
  ]
  return (
    <RDGrid style={{ paddingTop: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
      <RDGrid container style={{ paddingTop: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
        <TopMenuBar appSetState={setState} appState={state} />
        <RDTabs
          value={verticalTabValue}
          orientation={'horizontal'}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          style={{ minHeight: '36px', minWidth: '40px' }}
          onChange={(_, value?: any) => {
            setVerticalTabValue(value)
          }}>
          {SystemSetupTabs(state, clauseList).map((tab, idx) => {
            return (
              <RDTab
                vertical={false}
                iconPosition='start'
                key={`SystemSetupTabs-tab-${idx}`}
                label={tab.label}
                {...systemA11yProps(tab.name, idx)}
                value={idx}
                icon={<RDIcon style={{ marginLeft: 18, marginRight: 18 }} size={'sm'} name={'default'} />}
              />
            )
          })}
        </RDTabs>
        <RDSwipeableViews index={verticalTabValue}>
          {SystemSetupTabs(state, clauseList).map((tab, idx) => {
            return (
              <RDFixedTabPanel key={`SystemSetupTabs-page-${idx}`} value={verticalTabValue} index={idx} dir={'ltr'}>
                {tab.component}
              </RDFixedTabPanel>
            )
          })}
        </RDSwipeableViews>
        {/* <TopPaneComponent token={state.authToken} filter={state.filter} clauseList={clauseList} /> */}
        {/* {botOpen && (
          <MemoizedDraggableResizableDialog open={botOpen} onClose={() => setBotOpen(false)}>
            <RDChatBotify
              sessionID={uuidv4()}
              token={state.authToken}
              cacheName={state.contextCacheName}
              setBotOpen={setBotOpen}></RDChatBotify>
          </MemoizedDraggableResizableDialog>
          //   </Box>
          // </Modal>
        )} */}
      </RDGrid>
      {/* {!botOpen && state.contextCacheName?.length > 0 && (
        <RDButton
          onClick={() => setBotOpen(true)}
          sx={{
            position: 'relative',
            top: '-2%',
            left: '98%',
            transform: 'translate(-100%, -100%)',
            width: '30px',
            height: '30px',
            padding: 0,
            boxShadow: 24,
            zIndex: 2000,
            paddingTop: 0,
          }}>
          <img height='28px' width='28px' src={'assets/raindrop.png'} alt={'raindrop'} />
        </RDButton>
      )} */}
    </RDGrid>
  )
}

const propsAreEqual = (prevProps: any, nextProps: any) => {
  return prevProps.open === nextProps.open
}

const DraggableResizableDialog = ({ open, onClose, children }) => {
  return (
    <Dialog
      open={open}
      id='rd-chatbot-dialog'
      onClose={onClose}
      slotProps={
        {
          paper: {
            style: {
              position: 'absolute',
              bottom: '10px',
              right: '10px',
            },
          },
        } as any
      }
      fullScreen={false}
      PaperComponent={(props) => (
        <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
          <ResizableBox
            width='100%'
            height={400}
            className='rd-chatbot-draggable'
            minConstraints={[300, 200]}
            maxConstraints={[800, Infinity]}
            style={{
              backgroundColor: RDPaletteLight.paper,
              padding: 0,
              borderRadius: 9,
              position: 'absolute',
              bottom: '10px',
              right: '10px',
            }}
            resizeHandles={['nw']}
            {...props}
          />
        </Draggable>
      )}>
      <Box
        display='flex'
        alignItems='center'
        id='draggable-dialog-title'
        justifyContent='space-between'
        style={{
          paddingBottom: 5,
          paddingTop: 5,
          background: RDPaletteLight.link,
          cursor: 'move',
        }}>
        <RDIcon name='rain' size='xl' scale={3} style={{ marginTop: '3px', marginLeft: 40 }} />
        <Box flexGrow={1}></Box>
        <RDButton
          style={{ height: 40, width: 40, color: 'black', margin: 0 }}
          onClick={(evt) => {
            evt.stopPropagation()
            onClose()
          }}>
          <Close style={{ height: 20, width: 20 }} />
        </RDButton>
      </Box>
      <DialogContent style={{ padding: 0 }}>{children}</DialogContent>
    </Dialog>
  )
}

export default AddInBody

export const MemoizedDraggableResizableDialog = React.memo(DraggableResizableDialog, propsAreEqual)
