import React, { LegacyRef, forwardRef } from 'react'
import SwipeableViews, { SwipeableViewsProps } from 'react-swipeable-views'

interface RDSwipeableViewsProps extends SwipeableViewsProps {
  tabs?: React.ReactNode
  children?: React.ReactNode
  index?: number
}
export const RDSwipeableViews = forwardRef((props: RDSwipeableViewsProps, ref: LegacyRef<any>) => {
  return (
    <>
      {props.tabs}
      <SwipeableViews
        ref={ref as any}
        {...props}
        style={{ height: 'calc(100% - 60px)', width: '100%' }}
        containerStyle={{ height: 'calc(100% - 60px)' }}>
        {props.children}
      </SwipeableViews>
    </>
  )
})
