import React, { useEffect } from 'react'
import { CircularProgress } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import RDChatBotify from './Shared/Wrappers/RDChatBotify/RDChatBotify'
import { RDGrid } from './Shared/Wrappers/RDGrid'

export interface AssistantTabProps {
  token: string
  cacheName: string
}
export enum SelectionOptions {
  'Full Document' = 'full_document',
  'Selection' = 'selection',
}

export const AssistantTabComponent = (props: AssistantTabProps) => {
  const { token, cacheName } = props
  const [isAnalyzing, setIsAnalyzing] = React.useState<boolean>(false)

  useEffect(() => {
    setIsAnalyzing(true)
  }, [])

  useEffect(() => {
    if (cacheName?.length > 0) {
      setIsAnalyzing(false)
    }
  }, [cacheName])

  return (
    <RDGrid
      style={{
        height: '100%',
        fontFamily: `"Gotham HTF", "Roboto", "Helvetica Neue", sans-serif`,
        fontSize: '16px',
        width: '100%',
        paddingTop: 0,
      }}>
      <RDGrid container justifyContent={'center'} style={{ paddingTop: 0 }}></RDGrid>
      {isAnalyzing && (
        <RDGrid container justifyContent={'center'} style={{ paddingTop: '35%', height: '100%' }}>
          <RDGrid>
            <RDGrid>
              <CircularProgress />
            </RDGrid>
          </RDGrid>
          <RDGrid>
            <RDGrid>
              <RDGrid sx={{ padding: 6 }}>{`Analyzing Document`}</RDGrid>
            </RDGrid>
          </RDGrid>
        </RDGrid>
      )}
      {!isAnalyzing && <RDChatBotify sessionID={uuidv4()} token={token} cacheName={cacheName}></RDChatBotify>}
    </RDGrid>
  )
}
