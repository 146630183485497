import React, { useState } from 'react'
import { Box, Modal, Tooltip, Typography } from '@mui/material'
import { Close, UndoOutlined } from '@material-ui/icons'
import { RDGrid } from './RDGrid'
import { RDFontFamily } from '../../../services/ThemeService'
import { HeaderListInterface, showDialog } from '../../TopPane/TopPaneHelpers'
import { RDButton } from '../../RDButton'

export const ListActionMenuButton = (props: { headerItem: HeaderListInterface; nextHeader: string }) => {
  const { headerItem, nextHeader } = props
  //const [menuEl, setMenuEl] = useState<HTMLElement>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 200,
    height: 200,
    padding: 0,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    zIndex: 2000,
    overflow: 'hidden',
  }

  return (
    <>
      {headerItem.clause?.length > 0 && (
        <>
          <Tooltip
            title='Replace with Clause Library'
            placement='top'
            slotProps={{
              tooltip: {
                sx: {
                  backgroundColor: '#FAFAFA',
                  color: 'black',
                  fontSize: 12,
                  borderRadius: 4,
                  padding: '10px',
                  maxHeight: 100,
                },
              },
            }}>
            <RDButton
              onClick={(evt) => {
                evt.stopPropagation()
                showDialog(headerItem, nextHeader)
              }}>
              <UndoOutlined fontSize='medium' />
            </RDButton>
          </Tooltip>
          <Modal
            open={isOpen}
            onClose={() => {
              setIsOpen(false)
            }}>
            <Box sx={style}>
              <RDGrid container style={{ margin: 0, padding: 0 }}>
                <RDGrid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                  justifyContent={'space-between'}
                  style={{ marginLeft: 12, marginTop: 12 }}>
                  <Typography fontFamily={RDFontFamily} fontSize={16} marginRight={6}>
                    Risk Analysis
                  </Typography>
                  <RDButton
                    sx={{ alignSelf: 'flex-end' }}
                    onClick={(evt) => {
                      evt.stopPropagation()
                      setIsOpen(false)
                      //setMenuEl(null)
                    }}>
                    <Close style={{ height: 14, width: 14 }} />
                  </RDButton>
                </RDGrid>
                <RDGrid>
                  <Typography
                    variant='body2'
                    style={{ marginTop: 12, marginLeft: 12, overflowY: 'scroll', maxHeight: 125 }}>
                    {headerItem.risk?.description}
                  </Typography>
                </RDGrid>
              </RDGrid>
            </Box>
          </Modal>{' '}
        </>
      )}
    </>
  )
}
