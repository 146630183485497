import React, { forwardRef, Suspense } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { StarOutlined } from '@material-ui/icons'

export const RDIconComponents = {
  rain: { comp: undefined },
  star: { comp: StarOutlined, color: '#FF3333' },
  default: { comp: undefined },
}

export type RDIconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl'

export interface RDIconProps extends SvgIconProps {
  name?: keyof typeof RDIconComponents
  size?: RDIconSize
  addColor?: boolean
  scale?: number
  style?: any
  button?: boolean
  badgeName?: string
  isOn?: boolean
  attributeName?: string
  disabled?: boolean
}

const getRDIcon = (name: string) => {
  switch (name) {
    case 'rain':
      return (
        <SvgIcon viewBox='0 0 120.625 50'>
          <path
            d='M 7.846 46.392 L 1.569 46.392 L 1.569 29.576 C 1.569 24.307 3.952 20.367 8.075 17.938 C 13.116 15.095 19.268 15.061 24.341 17.847 C 28.529 20.23 31.078 24.713 30.985 29.531 L 24.799 29.531 C 24.799 26.003 22.875 24.17 21.271 23.253 C 18.141 21.559 14.367 21.559 11.237 23.253 C 8.946 24.628 7.8 26.69 7.846 29.531 L 7.846 46.392 Z'
            fill='#34a1d5'
            id='path130'
          />
          <path
            d='M 54.857 16.93 C 52.962 16.179 50.938 15.805 48.9 15.831 C 40.506 15.806 33.688 22.603 33.688 30.997 C 33.688 39.382 40.515 46.392 48.9 46.392 C 52.453 46.344 55.876 45.046 58.568 42.726 L 58.568 46.392 L 64.112 46.392 L 64.112 30.997 C 64.128 24.876 60.484 19.338 54.857 16.93 Z M 48.9 40.665 C 41.458 40.668 36.803 32.613 40.521 26.167 C 42.248 23.172 45.443 21.328 48.9 21.329 C 50.189 21.315 51.468 21.565 52.657 22.062 C 59.535 24.904 60.757 34.127 54.857 38.662 C 53.149 39.974 51.053 40.679 48.9 40.665 Z'
            id='path132'
            fill='#004991'
          />
          <path
            d='M 84.043 46.392 L 77.858 46.392 L 77.858 29.576 C 77.858 24.307 80.149 20.367 84.318 17.938 C 89.342 15.095 95.482 15.06 100.538 17.847 C 104.799 20.275 107.228 24.032 107.228 29.531 L 107.228 46.392 L 100.996 46.392 L 100.996 29.531 C 100.965 26.974 99.635 24.609 97.468 23.253 C 94.338 21.559 90.564 21.559 87.434 23.253 C 85.143 24.628 84.043 26.644 84.043 29.531 L 84.043 46.392 Z'
            fill='#34a1d5'
            id='path134'
          />
          <rect x='68.511' y='18.534' width='5.911' height='27.858' id='rect142' fill='#004991' />
          <circle cx='71.489' cy='10.882' r='4.49' id='circle144' fill='#004991' />
        </SvgIcon>
      )
    case 'star':
      return <StarOutlined />
    case 'default':
      return null
  }
  const Comp = RDIconComponents[name] ? RDIconComponents[name]['comp'] : RDIconComponents['default']['comp']
  return <Comp />
}

export const RDBaseIcon = forwardRef((props: RDIconProps, ref) => {
  const { size, addColor, style, button, disabled, badgeName, attributeName, isOn, ...rest } = props

  const name = props.name?.toLowerCase()
  let scale: number = 1

  switch (props.size) {
    case 'xs':
      scale = 0.6
      break
    case 'sm':
      scale = 0.8
      break
    case 'md':
      scale = 1
      break
    case 'lg':
      scale = 1.1
      break
    case 'xl':
      scale = 2
      break
    case 'xxl':
      scale = 5
      break
    case 'xxxl':
      scale = 10
      break
    case 'xxxxl':
      scale = 20
      break
    default:
      scale = 0.8
  }

  if (props?.scale) {
    scale = props.scale
  }

  //copy props style
  const newStyle = style ? { ...style } : {}

  //transform scale
  if (!newStyle['transform']) {
    newStyle['transform'] = 'scale(' + scale.toString() + ')'
  }

  //add color
  if (addColor && RDIconComponents[name] && RDIconComponents[name]['color']) {
    newStyle['color'] = RDIconComponents[name]['color']
  }

  if (RDIconComponents[name]?.['comp'] && !button && !style?.color) {
    newStyle['opacity'] = 0.7 // dim black icons in light theme
  }

  const icon = getRDIcon(name ? name : 'default')
    ? React.cloneElement(getRDIcon(name ? name : 'default'), {
        ...rest,
        ref: ref,
        style: newStyle,
        className: props.button ? 'blueRDIconButton' : null,
      })
    : null

  return <Suspense fallback={<div></div>}>{icon}</Suspense>
})

export const RDIcon = React.memo(RDBaseIcon)
