import * as React from 'react'
import { styled } from '@mui/material/styles'
import Tabs, { TabsProps } from '@mui/material/Tabs'
import { CustomTheme } from '../../types/theme'

export const RDTabs = styled((props: TabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))((props) => {
  const theme = props.theme as CustomTheme
  return {
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      padding: 2,
      backgroundColor: theme.rdPalette.raindrop_blue,
    },
  }
})
