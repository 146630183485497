import * as React from 'react'
import Tab, { TabProps } from '@mui/material/Tab'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import useTheme from '@mui/material/styles/useTheme'
import { CustomTheme } from '../../types/theme'

interface RDTabProps extends TabProps {
  vertical?: boolean
}

export const RDTab = (props: RDTabProps) => {
  const classes = useStyles({ ...props, ...useTheme() })
  return <Tab {...props} classes={classes} />
}

const useStyles = makeStyles<CustomTheme, RDTabProps>((theme: CustomTheme) =>
  createStyles({
    root: (props) => {
      console.log(theme)
      return {
        justifyContent: props.vertical ? 'start' : 'center',
        paddingRight: props.vertical ? '6px' : '16px',
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.background.paper,
        },
        '&.Mui-focusVisible': {
          backgroundColor: theme.palette.background.paper,
        },
      }
    },
  })
)
