import React from 'react'
import { TabPanelProps } from '@mui/lab'
import { RDGrid } from '../Shared/Wrappers/RDGrid'

export interface RDFixedTabPanelProps extends Omit<TabPanelProps, 'value'> {
  dir?: string
  index: number | string
  value: number | string
}

export const RDFixedTabPanel = React.forwardRef((props: RDFixedTabPanelProps, ref: any) => {
  const { children, value, index, ...other } = props
  return (
    <RDGrid
      container
      ref={ref}
      role='tabpanel'
      direction={'column'}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ paddingTop: 0, height: '100%', overflowY: 'hidden', width: '100%' }}
      {...other}>
      <RDGrid style={{ height: '100%', placeContent: 'flex-start', overflowY: 'scroll' }}>{children}</RDGrid>
    </RDGrid>
  )
})
